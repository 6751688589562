import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import CheckIcon from '@mui/icons-material/Check';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const emails = ['username@gmail.com', 'user02@gmail.com'];

export function CustomDialog(props) {
  const { onClose, selectedValue, open,educationData } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <List sx={{ pt: 0, backgroundColor:"rgba(72,81,80,255)",paddingBottom:0}}>
        {educationData.map((email) => (
          <ListItem disableGutters key={email.id} style={{ backgroundColor:"rgba(72,81,80,255)",color:"white",borderBottom:"solid 1px rgba(95,99,100,255)"}}>
            <ListItemButton onClick={() => handleListItemClick(email.education)}>
               {
                selectedValue === email.education ? <CheckIcon sx={{paddingRight:1,marginRight:.5}}/> : <RadioButtonUncheckedIcon sx={{paddingRight:1,marginRight:1,fontSize:'20px'}}/>
               }
               
              <ListItemText primary={email.education} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}