import React from "react";
import { Stepper, StepConnector, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { setActiveNumber } from "../../feature/slice/tabSlice";
import { IoIosArrowForward } from "react-icons/io";
import { getStituation } from "./api";
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: "rgb(178, 9, 93)",
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));
const StepNumberIcon = styled("div")(({ theme, active }) => ({
  width: 24,
  height: 24,
  borderRadius: "50%",
  backgroundColor: active ? 'rgb(178, 9, 93)' : "#e0e0e0",
  color: "#fff",
  display: "flex",
  borderTopWidth: 2,
  alignItems: "center",
  justifyContent: "center",
}));
const StepNumberIconPartent = styled("div")(({ theme, active }) => ({
  width: 34,
  height: 34,
  borderRadius: "50%",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  borderWidth: 2,
  justifyContent: "center",
  border: "solid 3px rgb(178, 9, 93)",
  borderColor:"rgb(178, 9, 93)"
}));




function CustomStepper() {
    const dispatch = useDispatch();
    const {activeNumber} = useSelector(data => data.TabSlice);
    const {steps} = useSelector(data => data.TabSlice);
    const handlePrev = () => {
      if(steps!==1){
        dispatch(setActiveNumber({data:{steps:steps-1,previous:true}}))
        getStituation(steps - 1).then(({data})=>{
          dispatch(setActiveNumber({data:{stepsData:data}}))
        }); 
      }
     
    }

    const handleForward = () => {
      if(activeNumber>=steps){
        dispatch(setActiveNumber({data:{steps:steps+1}}))
        getStituation(steps + 1).then(({data})=>{
          dispatch(setActiveNumber({data:{stepsData:data}}))
        });  
        if((steps+1) == activeNumber){
          dispatch(setActiveNumber({data:{previous:false}}))
        }
      }
    }


  return (
    <div className="stepper-fix">
        
        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <div className="back-arrow-stepper" style={{marginLeft:"10px"}}>
          {/* <IoArrowBack /> */}
        </div>
      <Stepper activeStep={0} connector={<CustomConnector />}>
      <StepNumberIconPartent>
      <StepNumberIcon active={true}>
                  {steps}
                </StepNumberIcon>
      </StepNumberIconPartent>
      </Stepper>
      {
        activeNumber==steps? <div className="back-arrow-stepper" style={{background:"rgb(232 232 232)",marginRight:"10px"}}></div>:
     
      <div className="back-arrow-stepper" style={{background:"#bdbdbd",marginRight:"10px"}} onClick={handleForward}>
      <IoIosArrowForward />
      </div>
       }
    </Box>
    </div>
  );
}

export default CustomStepper;
