import * as yup from "yup"
import { lblRegisterUser } from "../labels/registerUser"
import { lblYup } from "../labels/yupLabels"
const EMAIL_REGEX = /(\<|^)[\w\d._%+-]+@(?:[\w\d-]+\.)+(\w{2,})(\>|$)/i;
export const RegisterUser = yup.object().shape({
    name:yup.string().required(lblRegisterUser.fullName + " " + lblYup.isRequired).max(150,lblRegisterUser.fullName + " - " + lblYup.max150),
    age:yup.string().required(lblRegisterUser.age + " " + lblYup.isRequired).max(2,lblRegisterUser.age + " - " + lblYup.max02).matches(/^\d{1,2}$/, lblYup.inValidAge),
    gender:yup.string().required(lblRegisterUser.gender + " " + lblYup.isRequired),
    email:yup.string().required(lblRegisterUser.email + " " + lblYup.isRequired)?.email(lblYup.inValidEmail).matches(EMAIL_REGEX,lblYup.inValidEmail).max(255,lblRegisterUser.email + " - " + lblYup.max255),
    city:yup.string().required(lblRegisterUser.city + " " + lblYup.isRequired).max(50,lblRegisterUser.city + " - " + lblYup.max50),
    education:yup.string().required(lblRegisterUser.education + " " + lblYup.isRequired).max(100,lblRegisterUser.education + " - " + lblYup.max100),
    occupyType:yup.string().required(lblRegisterUser.occupyType + " " + lblYup.isRequired)
})