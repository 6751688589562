import React, { useEffect, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import FlagIcon from "@mui/icons-material/Flag";
import { Box } from "@mui/material";
import { FaRegFlag } from "react-icons/fa6";
import { useSelector } from "react-redux";
const left = [
    3, 9, 13.5, 18, 23, 28, 33, 38, 43, 48, 53, 58, 63, 68, 73, 78, 83, 88, 93, 97
]
function CustomProgressBar() {
    const { steps } = useSelector((data) => data.TabSlice);
    const [progress, setProgress] = useState();

  useEffect(()=>{
    setProgress(steps*5)
  },[steps])
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",    
        margin: "20px auto",
        borderRadius: "8px",
      }}
    >
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: "24px",
          backgroundColor: "white",
          borderRadius: "50px",
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#a50063",
          },
        }}
      />
      <Box
        sx={{
          position: "absolute",
        left: `${left[steps-1]}%`,
          top: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#a50063",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "2px solid white",
        }}
      >
        <FaRegFlag style={{ color: "white", fontSize: "14px" }}/>
      </Box>
    </Box>
  );
}

export default CustomProgressBar;
