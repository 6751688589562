import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveNumber } from "../../feature/slice/tabSlice";
import { getStituation } from "./api";
import SituationAssessment from "./situation";

const SituationAssessmentIndex = () => {
  const dispatch = useDispatch();
  const {steps} = useSelector(data => data.TabSlice);
  const {stepsData} = useSelector(data => data.TabSlice);


  useEffect(()=>{
   if(steps == 1){
     getStituation(1).then((data)=>{
      if(data){
        dispatch(setActiveNumber({data:{stepsData:data.data}}))
      }
     
    }); 
   }  

  },[]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [steps]);

  const isData = Object.keys(stepsData).length > 0;

  return (
    <>
      <div className="" style={{background:"#F6F6F6"}}>
        {isData && <div style={{ paddingTop: "62px" }}>{<SituationAssessment componentData={stepsData}/>}</div>}
      </div>
    </>
  );
};

export default SituationAssessmentIndex;
