import React, { useEffect, useRef, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { Radar } from "react-chartjs-2";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { useNavigate } from "react-router-dom";
import img2 from "../../assets/images/new2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setResetData } from "../../feature/slice/tabSlice";
import { setResetUser } from "../../feature/slice/selfSlice";
import html2canvas from "html2canvas";
import axios from "axios";
import { PDFDocument } from "pdf-lib";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const options = {
  scales: {
    r: {
      ticks: { beginAtZero: true, min: 0, max: 9, stepSize: 2 },
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display:false,
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        padding: 10,
        color: "rgba(255, 99, 132, 1)",
        font: {
          size: 14,
        },
        generateLabels: (chart) => {
          const datasets = chart.data.datasets;
          return datasets.map((dataset, i) => ({
            text: dataset.label,
            fillStyle: dataset.borderColor,
            strokeStyle: dataset.borderColor,
            hidden: !chart.isDatasetVisible(i),
            lineCap: dataset.borderCapStyle,
            lineDash: dataset.borderDash,
            lineDashOffset: dataset.borderDashOffset,
            lineJoin: dataset.borderJoinStyle,
            lineWidth: dataset.borderWidth,
            pointStyle: "rectRounded",
            rotation: 0,
          }));
        },
      },
    },
  },
};

function ReportSummary() {
  const [state, setState] = useState({
    mainData: {},
  });
  const { mainData } = state;
  const { steps } = useSelector((data) => data.TabSlice);
  const { userId } = useSelector((data) => data.SelfSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (steps !== 20) {
      navigate("/");
    }
    document.body.classList.remove('no-scroll'); // Allow scrolling
    getIntial();
  }, []);

  const getIntial = async () => {
    const query = `?user_id=${userId}`;
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const result = await axios.get(window.GET_SPIDER_DATA + query, {
        headers: headers,
      });
      const labelsData = Object.keys(result.data)

    const remianData = labelsData.map((item) => {
        if (item === "Relationship Orientation") {
          return "Relationship\nOrientation"; // Add line break
        }
        if (item === "Operational Sensitivity") {
          return "Operational\nSensitivity"; // Add line break
        }
        return item; // Keep the item unchanged if no match
      });
      const innerData = {
       
        
        labels: remianData,
        datasets: [
          {
            label: "Your Score",
            data: Object.values(result.data),
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 1)",
            pointBackgroundColor: "rgba(255, 99, 132, 1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(255, 99, 132, 1)",
          },
        ],
      };
      setState((prev) => ({ ...prev, mainData: innerData }));
    } catch (error) {}
  };

  const handleSubmit = () => {
    dispatch(setResetData());
    dispatch(setResetUser());
  };
  const photoRef = useRef(null);
  


  const takeScreenshotAndSavePDF = async () => {
    photoRef.current.style.border = "solid 1px rgb(148 163 184)"
     photoRef.current.style.paddingLeft = "10px"
     photoRef.current.style.paddingRight= "10px"
    try {
      if (photoRef.current) {
        // Take a screenshot using html2canvas
        const canvas = await html2canvas(photoRef.current, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
  
        // Load the existing PDF file
        const existingPdfBytes = await fetch('/gc.pdf').then((res) => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
  
        // Get the second page of the PDF
        const pages = pdfDoc.getPages();
        
        const secondPage = pages[1]; // Index 1 is the second page
        const pageHeight = secondPage.getHeight();
        const pageWidth = secondPage.getWidth();
  
        // Get the dimensions of the second page
        const { width, height } = secondPage.getSize();
  
        // Add the image to the second page at the desired position
        const pngImage = await pdfDoc.embedPng(imgData);
        
        
        const pngDims = pngImage.scale(1); // Scale the image as needed
        const { width: imgWidth, height: imgHeight } = pngImage.scale(1);
        const IsyPosition = pageHeight - imgHeight - 50; // Adjust 50 for padding from the top
        const xPosition = (pageWidth - imgWidth) / 2; // Center horizontally
        const yPosition = 200; // Adjust padding from the bottom (set to 0 for exact bottom)
        // Check if image fits
        if (IsyPosition < 0) {
          console.error('Image is too tall for the available space.');
          return;
        }
        secondPage.drawImage(pngImage, {
          // x: 50, // Adjust the x-coordinate
          // y: height - pngDims.height - 100,
          x: xPosition,
          y: yPosition,
          width: pngDims.width,
          height: pngDims.height,
        });
  
        // Serialize the PDFDocument to bytes (saveable as a file)
        const pdfBytes = await pdfDoc.save();
  
        // Download the modified PDF
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'GrowthCompassReport.pdf';
        link.click();
        photoRef.current.style.border = "none"
        photoRef.current.style.paddingLeft = "0px"
        photoRef.current.style.paddingRight= "0px"
        setTimeout(() => {
          handleSubmit();  
        }, 6000);
        
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
    }
  };
  return (
    <>
      <div id="page-content">
        <Container
          className="d-flex flex-column align-items-center vh-100 bor-none"
          style={{ position: "relative" }}
        >
          <div className="">
            <div className="bor-container" style={{border:"none"}}>
              <div
                style={{ maxWidth: "400px", width: "100%" }}
                className="text-center mb-2 position-relative"
              >
                <img
                  src={img2}
                  alt="Completed Badge"
                  className="img-fluid mb-4"
                />
                <p style={{fontSize:'24px',fontWeight:500}}>Thank you!</p>
                <p style={{color:'#121416',padding:"0px 38px",fontWeight:400}}>We appreciate the time you’ve taken to complete the assessment. <a href="#" onClick={takeScreenshotAndSavePDF}>Download</a> your traits chart and the overall attribute descriptions to uncover insights that empower your next steps.</p>
                <div style={{padding:'0px 20px 20px 20px'}}> <hr /></div>
                {/* */}
                <p className="text-uppercase montserrat-subrayada-regular" style={{color:"#5B6F8E",fontSize:'13px',paddingLeft:"10px",textAlign:'start',fontWeight:700}}>Report Summary</p>
              </div>

              <div
                style={{
                  paddingBottom: "20px",
                  display:'flex',
                  justifyContent:'center'
                }}
                ref={photoRef}
              >
                {mainData?.labels ? (
                  <Radar data={mainData} options={options} />
                ) : null}
               
              </div>
              {true && 
                <Button variant="" style={{background:"white"}}>
                  </Button>
                }
            </div>
          </div>
        </Container>
      </div>
      
    </>
  );
}

export default ReportSummary;
